import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateSessionPlaytest"] */ "/app/src/src/entities/session/ui/CreateSessionPlaytest.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/images/logo-white.svg");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Container/Container.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Icon/Icon.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/Image/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/LanguageSwitcher/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/LocalizedLink/LocalizedLink.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/Video/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthModalProvider"] */ "/app/src/src/widgets/Auth/context/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/FooterPlaytest/ui/FooterPlaytest.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/HeaderPlaytest/ui/Auth/Auth.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/HeaderPlaytest/ui/HeaderPlaytest.scss");
